<template lang="pug">
div.container
  div(v-if="!loaded")
    loading-spinner(message="Loading members...")
  div(v-if="loaded")
    div.row
      h1 Membership Changes

    div.jumbotron.text-center
      b-form.d-flex.justify-content-center(inline) Show data from between
        datepicker.m-2(v-model="startDate" v-on:input="computeMemberships()" :bootstrap-styling="true")
        |
        | and
        datepicker.m-2(v-model="endDate" v-on:input="computeMemberships()" :bootstrap-styling="true")

    div.row
      h2 New Members
      table.table.table-hover.table-striped
        thead.thead-dark
          tr
            th Last Name
            th First Name
            th Membership Type
            th Join Date
        tbody
          tr.table-row-link(v-for="member in newMembers" :key="member._id" @click="$router.push(member.link)")
            td {{member.lastName}}
            td {{member.firstName}}
            td {{member.membershipType}}
            td {{member.createdDate | moment('DD.MM.YYYY')}}

    div.row.justify-content-center.text-center
      p {{newMembers.length}} new members
    textarea.form-control(rows="2" v-if="globalState.userRole >= 4") {{newMembersCsv}}
    hr/
    div.row
      h2 Renewed Members
      table.table.table-hover.table-striped
        thead.thead-dark
          tr
            th Last Name
            th First Name
            th Membership Type
            th Renewal Date
        tbody
          tr.table-row-link(v-for="member in renewedMembers" :key="member._id" @click="$router.push(member.link)")
            td {{member.lastName}}
            td {{member.firstName}}
            td {{member.membershipType}}
            td {{member.lastRenewed | moment('DD.MM.YYYY')}}

    div.row.justify-content-center.text-center
      p {{renewedMembers.length}} renewed members
    textarea.form-control(rows="2" v-if="globalState.userRole >= 4") {{renewedMembersCsv}}
    hr/
    div.row
      h2 Expired Members
      table.table.table-hover.table-striped
        thead.thead-dark
          tr
            th Last Name
            th First Name
            th Membership Type
            th Expiry Date
        tbody
          tr.table-row-link(v-for="member in expiredMembers" :key="member._id" @click="$router.push(member.link)")
            td {{member.lastName}}
            td {{member.firstName}}
            td {{member.membershipType}}
            td {{member.validUntil | moment('MMMM YYYY')}}

    div.row.justify-content-center.text-center
      p {{expiredMembers.length}} expired members
    textarea.form-control(rows="2" v-if="globalState.userRole >= 4") {{expiredMembersCsv}}
    hr/
    div.row
      h2 Active Members
      br/
      p.text-muted Members may have expired during these dates but did have an active membership during the date range.
      table.table.table-hover.table-striped
        thead.thead-dark
          tr
            th Last Name
            th First Name
            th Membership Type
            th Expiry Date
        tbody
          tr.table-row-link(v-for="member in activeMembers" :key="member._id" @click="$router.push(member.link)")
            td {{member.lastName}}
            td {{member.firstName}}
            td {{member.membershipType}}
            td(v-if="member.membershipType != 'Lifetime'") {{member.validUntil | moment('MMMM YYYY')}}
            td(v-else) Never!

    div.row.justify-content-center.text-center
      p {{activeMembers.length}} active members
    textarea.form-control(rows="2" v-if="globalState.userRole >= 4") {{activeMembersCsv}}
</template>

<script>
import globalState from '@/main.js'
import Datepicker from 'vuejs-datepicker'

export default {
  components: {
    Datepicker
  },
  data: function () {
    return {
      globalState,
      members: [],
      affiliates: [],
      newMembers: [],
      expiredMembers: [],
      renewedMembers: [],
      activeMembers: [],
      newMembersCsv: '',
      expiredMembersCsv: '',
      renewedMembersCsv: '',
      activeMembersCsv: '',
      csvHeader: '"First name","Last name","E-mail address"\n',
      endDate: new Date(),
      startDate: new Date(),
      loaded: false
    }
  },
  mounted: function () {
    this.startDate.setMonth(this.startDate.getMonth() - 1)
    this.startDate.setHours(0, 0, 1)
    this.endDate.setHours(23, 59, 59)

    Promise.all([this.axios.get('/api/individual-members'), this.axios.get('/api/affiliates')])
      .then(responses => {
        this.members = responses[0].data
        this.affiliates = responses[1].data
        this.loaded = true
        this.computeMemberships()
      })
  },
  methods: {
    addMemberToCsvString: function (target, member) {
      target += '"' +
      member.firstName + '","' +
      member.lastName + '","' +
      member.email + '"\n'
      return target
    },
    computeMemberships: function() {
      console.log(this.startDate)
      this.newMembersCsv = this.csvHeader
      this.expiredMembersCsv = this.csvHeader
      this.renewedMembersCsv = this.csvHeader
      this.activeMembersCsv = this.csvHeader

      this.newMembers = []
      this.expiredMembers = []
      this.renewedMembers = []
      this.activeMembers = []

      for (let member of this.members) {
        member.validUntil = new Date(member.validUntil)
        member.createdDate = new Date(member.createdDate)
        member.lastRenewed = new Date(member.lastRenewed)
        member.link = '/individual-members/' + member._id

        if (member.validUntil >= this.startDate && member.validUntil <= this.endDate) {
          this.expiredMembersCsv = this.addMemberToCsvString(this.expiredMembersCsv, member)
          this.expiredMembers.push(member)
        } else if (member.createdDate >= this.startDate && member.createdDate <= this.endDate) {
          this.newMembersCsv = this.addMemberToCsvString(this.newMembersCsv, member)
          this.newMembers.push(member)
        } else if (member.lastRenewed >= this.startDate && member.lastRenewed <= this.endDate) {
          this.renewedMembersCsv = this.addMemberToCsvString(this.renewedMembersCsv, member)
          this.renewedMembers.push(member)
        }

        if (member.validUntil >= this.startDate) {
          this.activeMembersCsv = this.addMemberToCsvString(this.activeMembersCsv, member)
          this.activeMembers.push(member)
        }
      }

      for (let affiliate of this.affiliates) {
        affiliate.validUntil = new Date(affiliate.validUntil)
        affiliate.createdDate = new Date(affiliate.createdDate)

        for (let member of affiliate.members) {
          member.membershipType = "Affiliate"
          member.validUntil = affiliate.validUntil
          member.createdDate = affiliate.createdDate
          member.link = '/affiliates/' + affiliate._id

          if (affiliate.validUntil >= this.startDate && affiliate.validUntil <= this.endDate) {
            this.expiredMembersCsv = this.addMemberToCsvString(this.expiredMembersCsv, member)
            this.expiredMembers.push(member)
          } else if (affiliate.createdDate >= this.startDate && affiliate.createdDate <= this.endDate) {
            this.newMembersCsv = this.addMemberToCsvString(this.newMembersCsv, member)
            this.newMembers.push(member)
          }

          if (affiliate.validUntil >= this.startDate) {
            this.activeMembersCsv = this.addMemberToCsvString(this.activeMembersCsv, member)
            this.activeMembers.push(member)
          }
        }
      }
    }
  }
}
</script>
